.profile__card {
  background-color: darkgray;
  /* background-image: url('https://images.unsplash.com/photo-1613141276543-74107d388095?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80'); */

  /* background-image: url('https://images.unsplash.com/photo-1613219332203-8513309bd7a6?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'); */
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: content-box;
  object-fit: cover;
}
.profile__avatar {
  height: 50px;
  height: 50px;
}
.profile__details {
  display: flex;
  justify-content: space-between;
}
.profile__img__wrapper {
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  margin: -70px 5px 0px 15px;
  max-width: 100%;
  /* padding:5px; */
  vertical-align: bottom;
  position: relative;
}

.profile__img {
  border: 4px solid white;
  border-radius: 50%;
  color: #fff;
  height: 160px;
  width: 160px;
}
.edit__profile_wrapper {
  padding: 5px;
  margin: 5px;
}
.edit__profile_btn {
  color: var(--twitter-color) !important;
  /* color: white !important; */
  border: 2px solid #43b0f4 !important;
  text-transform: none !important;
  border-radius: 30px !important;
}
.profile__user_name {
  font-weight: 800;
  margin-left: 50px;
}
.image-overlay {
  position: absolute;
  bottom: 8px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  text-align: center;
  left: 4px;
  border-radius: 50%;
  height: 160px;
  width: 160px;
}
.profile__img__wrapper:hover .image-overlay {
  opacity: 1;
}
.camera-img-wrapper {
  position: relative;
}
.camera-img {
  position: absolute;
  top: 64px;
  left: 69px;
}
.d-none {
  display: none;
}
.label {
  cursor: pointer;
}
