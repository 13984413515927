.tweetBox {
  padding-bottom: 10px;
  border-bottom: 8px solid var(--twitter-background);
  padding-right: 10px;
}

.tweetBox > form {
  display: flex;
  flex-direction: column;
}
.tweetBox__input {
  padding: 20px;
  display: flex;
}

.tweetBox__input > input {
  flex: 1;
  margin-left: 20px;
  font-size: 16px;
  border: none;
}

.tweetBox__tweetButton {
  background-color: var(--twitter-color) !important;
  border: none !important;
  color: white !important;
  font-weight: 900 !important;
  text-transform: inherit !important;
  border-radius: 30px !important;
  width: 80px;
  height: 40px !important;
  /* margin-top: 20px !important; */
  margin-left: auto !important;
}
.tweetBox__imageInput {
  padding: 10px;
  border: none;
  margin-left: 20px;
}

.tweetBox__details {
  padding: 10px;
  border: none;
  margin-left: 20px;
  flex: 1;
}
.tweetBox__category {
  display: flex;
  margin-top: 10px;
  height: 32px;
  margin-left: 10px;
}
.upload__image {
  color: var(--twitter-color);
  font-size: 30px !important;
  margin-left: 20px;
  /* margin-bottom: 20px; */
}
.upload__image:hover {
  background-color: #e8f5fe;
  cursor: pointer;
  border-radius: 50%;
}
.tweet__footer {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.image__upload_icon {
  flex: 3;
}
