.feed {
  flex: 0.6;
  border-right: 1px solid var(--twitter-background);
  min-width: fit-content;
  overflow: scroll;
}
/* hide scrollbar for chrome, safari and opera */
.feed::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.feed {
  -ms-overflow-style: none; /* IE and edge */
  scrollbar-width: none; /* Firefox */
}

.feed__header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  border: 1px solid var(--twitter-background);
  padding: 15px 20px;
}

.feed__header > h2 {
  font-size: 20px;
  font-weight: 800;
}
.load-more{
  display: flex;
  justify-content: center;
}
.spinner-loading{
  display: flex;
  justify-content: center;
}
