.detail__main {
  height: 100vh;
}
.comment-sections {
  height: 500px;
}
.detail {
  border-bottom: 1px solid var(--twitter-background);
  padding-bottom: 10px;
}
.detail__header {
  display: flex;
  justify-content: space-between;
}
.detail__headerText {
  display: flex;
  flex: 2;
  justify-content: center;
  padding: 20px 0 20px 0px;
}
.detail__h3 {
  width: 95%;
  font-size: 15px;
  margin-bottom: 5px;
}
.detail__avatar {
  padding: 15px;
}
/* .detail:hover {
  background-color: #f7f7f7;
  cursor: pointer;
} */

.detail__body {
  padding: 0 15px 0 15px;
}

.detail__body > img {
  border-radius: 20px;
}

.detail__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.detail__description {
  margin-bottom: 10px;
  font-size: 15px;
  padding: 15px;
  max-width: 720px;
}
.detail__description > p {
  text-align: justify;
}

.detail__badge {
  font-size: 14px !important;
  color: var(--twitter-color);
}

.detail_image__container {
  max-width: 700px;
  overflow: hidden;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-height: 380px;
}
.detail__image {
  border-radius: 30px;
  width: 100%;
  object-fit: contain;
}
.detail_image__container:hover {
  cursor: pointer;
}
.detail__timestamp {
  font-size: 15px;
  padding: 15px;
  color: rgb(91, 112, 131);
  border-bottom: 1px solid lightgray;
}
.comment__description {
  margin-bottom: 10px;
  max-width: 720px;
}
