.post {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid var(--twitter-background);
  padding-bottom: 10px;
}
.post:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}

.post__body {
  flex: 1;
  padding: 10px;
}

.post__body > img {
  border-radius: 20px;
}

.post__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.post__headerDescription {
  margin-bottom: 10px;
  font-size: 15px;
}
.post__headerText {
  display: flex;
  justify-content: space-between;
}
.header__timestamp {
  font-size: 13px;
  margin-left: 10px;
  color: rgb(91, 112, 131);
}

/* .post__headerText > more-horizon{
  
} */

.post__headerText > h3 {
  font-size: 15px;
  margin-bottom: 5px;
}

.post__badge {
  font-size: 14px !important;
  color: var(--twitter-color);
}

.post__headerSpecial {
  font-weight: 600;
  font-size: 12px;
  color: gray;
}

.post__avatar {
  padding: 20px;
}
.image__container {
  max-width: 600px;
  overflow: hidden;
}
.post__image {
  border-radius: 30px;
  width: 100%;
  object-fit: contain;
}
