.widgets {
  flex: 0.2;
}

.widgets__input {
  display: flex;
  align-items: center;
  background-color: var(--twitter-background);
  padding: 10px;
  border-radius: 20px;
  margin-top: 10px;
  margin-left: 20px;
}
.widgets__input > input {
  border: none;
  background-color: var(--twitter-background);
}
.widgets__searchIcon {
  color: gray;
}
.widgets__widgetContainer {
  margin-top: 15px;
  margin-left: 20px;
  padding: 20px;
  background-color: #f5f8fa;
  border-radius: 20px;
}
.widgets__widgetContainer > h2 {
  font-size: 18px;
  font-weight: 800;
}
